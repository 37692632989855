import React from 'react'
import './about.css'

const About2 = () => {
  return (
    <div className='sbb__about'>
      <div className='sbb__about-imgcontainer'/>
        <div className='sbb__about-text'>
          <h1 className='sbb__about-header'>
              Kuben Tomtebo
        </h1>
        <p className='sbb__about-paragraph'>
        Här erbjuder vi våran take på New York style pizza, våra redan älskade smashburgare samt fräscha smakrika sallader & grillrätter
        </p>
        <a style={{textDecoration:"none"}} href='https://kubentomtebo.yabie.online/'><button className='sbb__about-button'>SE MENYN OCH BESTÄLL</button></a>
      </div>
    </div>
  )
}

export default About2