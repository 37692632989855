import React from 'react'
import './about.css'

const About = () => {
  return (
      <div className='sbb__about'>
          <img className='sbb__about-img' src={require('../../assets/about.png')}/>
          <div className='sbb__about-text'>
              <h1 className='sbb__about-header'>
                  Kuben Ersboda
              </h1>
              <p className='sbb__about-paragraph'>
                 Här erbjuder vi smakrika smashburgare på lokalproducerat nötkött samt ett brett utbud på grillrätter
              </p>
              <a style={{textDecoration:"none"}} href='https://kuben.yabie.online'><button className='sbb__about-button'>SE MENYN OCH BESTÄLL</button></a>
          </div>
      </div>
  )
}

export default About