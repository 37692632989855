import React from 'react'
import './map.css';

const Map = () => {
  return (
    <div className='sbb__map' id='map'>
        <h1 className='sbb__map-heading'>
            HITTA TILL TOMTEBO
        </h1>
        <div className='sbb__map-flex'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d440.25839497205834!2d20.363004428645468!3d63.80791509856178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x467c5b49956ebaa9%3A0x21b62f8afa188d4c!2zS8OlbGhhZ3N2w6RnZW4gMTYsIDkwNyA1NSBVbWXDpQ!5e0!3m2!1ssv!2sse!4v1725302907921!5m2!1ssv!2sse"
            height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='sbb__map-map'>
            </iframe>
            <div className='sbb__map-info'>
                <h1>ÖPPETTIDER:</h1>
                <p>
                    Måndag till Torsdag 16:00-20:00
                    <br/><br/>
                    Fredag 11:00-20:30, Lördag-Söndag 12:00-20:30
                    <br/><br/>
                    
                </p>
            </div>
         </div>
    </div>
  )
}

export default Map