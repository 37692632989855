import React from 'react'
import './hero.css'
import '../about/about.css'
import Arrow from '../../assets/Arrow.svg';
import Logo from '../../assets/logo.svg';
import Header from '../header/Header';

const Hero = () => {
  return (
    <div className='sbb__hero'>
        <div className="sbb__hero-logo">
            <img src={Logo} height="150%"/>
        </div>
        <div className="sbb__hero-text-h1">
            Nu har vi öppnat även på Tomtebo!
        </div>
        <div className="sbb__hero-text-h3">
            Skippa kön och beställ direkt online!
        </div>
        <div className='sbb__hero-button'>
        <a style={{textDecoration:"none"}} href='https://kuben.yabie.online'><button className='sbb__about-button'>ERSBODA</button></a>
        <a style={{textDecoration:"none"}} href='https://kubentomtebo.yabie.online'><button className='sbb__about-button'>TOMTEBO</button></a>
        </div>
        
    </div>
  )
}

export default Hero