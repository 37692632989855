import logo from './logo.svg';
import './App.css';
import {Header, Hero, Menu, Map, Footer, About, Form, About2} from './components'
import Map2 from './components/map/map2';
import "./fonts/Heavitas.ttf";
import "./fonts/AvenirNextLTPro-Regular.otf";

function App() {
  return (
    <div className="App">
        <Header/>
        <Hero/>
        <About/>
        <About2/>
        <Map2/>
        <Map/>
      
        <Form/>
        <Footer />
    </div>
  );
}

export default App;
